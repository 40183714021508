import Layout from "../components/layout"
import React from "react"
import {
  Paragraph1,
  ContactContent,
  ContactWrapper,
} from "../styles/contact/style"
import {
  Title,
  Title1,
  Title2,
  Title3,
  Paragraph,
  MailTo,
  Paragraph2,
} from "../styles/policies/style"

const Contact = () => {
  return (
    <Layout>
      <ContactWrapper>
        <ContactContent>
          <Title>Privacy policy</Title>
          <Paragraph1>
            <Title1>PRIVACY POLICY</Title1>
            <Title3>A. PRIVACY</Title3>
            <Paragraph>
              We really value your privacy so we'd like to explain our privacy
              policy to you. Some of the information below might be a little
              technical but it's important that you read and understand what
              follows because it explains how we will collect and use your
              personal information.
            </Paragraph>
            <Title2>
              How does Michael Allen & Partners collect your personal
              information?
            </Title2>
            <Paragraph>
              We may collect your personal information through some of the
              following ways:
            </Paragraph>
            <Paragraph>
              <Paragraph2>
                • When you register or use
                <MailTo href="mailto:info@michaelallenpartners.com">
                  info@michaelallenpartners.com
                </MailTo>
                (the "Website").
              </Paragraph2>
              <Paragraph2>
                • When completing any surveys that we send to you for research
                purposes (although you are under no obligation to complete
                these).
              </Paragraph2>
              <Paragraph2>
                • When completing any forms for transactional, employment or
                other purposes.
              </Paragraph2>
            </Paragraph>
            <Title2>
              What sort of personal information will we collect about you?
            </Title2>
            <Paragraph>
              In addition to personal details such as your name, address, email
              and telephone number, we may also collect some of the following
              personal information about you:
            </Paragraph>
            <Paragraph>
              <Paragraph2>
                • Details of transactions you make with us (whether via the
                Website, Telephone or at an event).
              </Paragraph2>
              <Paragraph2>• Details of your visits to the Website.</Paragraph2>
              <Paragraph2>
                • Details of when you contact us by email, post or telephone.
                Please note that we my keep a record of that correspondence.
              </Paragraph2>
              <Paragraph2>
                • Any other information from which we can identify you (or which
                is available on public registers).
              </Paragraph2>
            </Paragraph>
            <Title2> What do we use your personal information for?</Title2>
            <Paragraph>
              We will treat all your personal information as private and
              confidential (although we reserve the right to disclose this
              information in certain circumstances, as set out below).
            </Paragraph>
            <Paragraph>
              We confirm that any personal information which you provide to us
              will be held securely and in accordance with the registration that
              we have with the Data Commissioner's Office. We will use and
              process your personal information in accordance with the Data
              Protection Act 1998, as amended from time to time, as well as any
              other relevant data protection or privacy legislation. The types
              of uses we will make of your personal information shall include
              the following:
            </Paragraph>
            <Paragraph>
              <Paragraph2>
                • Process any orders that you make via the Website, via
                telephone (please note that your details may need to be passed
                to a third party in order for them to supply or deliver the
                product or service that you ordered and we may retain your
                details for a reasonable period of time after you have completed
                the transaction in order to fulfil any contractual obligations
                such as refunds, etc.).
              </Paragraph2>
              <Paragraph2>
                • Process payments and prevent fraudulent transactions (we may
                retain your details for these purposes for a reasonable period
                of time and we may pass your details to a third party to carry
                out these functions).
              </Paragraph2>
              <Paragraph2>
                • With your consent, retain your personal information,
                preferences and details of your transactions in order to keep
                you informed by email, post, mobile messaging, telephone and/or
                through other digital means including social media platforms,
                about products and services (including special offers,
                discounts, promotions, events, competitions and so on) offered
                by us.
              </Paragraph2>
              <Paragraph2>
                • We will use your personal information to maintain and update
                your account (e.g. such as a change of address).
              </Paragraph2>
              <Paragraph2>
                • Improve the content and appearance of the Website to ensure
                that content is presented in the most effective manner for you.
              </Paragraph2>
            </Paragraph>
            <Paragraph>
              Enforce or apply our terms and conditions and other agreements; or
              to protect the rights, property, or safety of Michael Allen &
              Partners, our customers or others. This includes exchanging
              information with other companies and organisations for the
              purposes of fraud protection.
            </Paragraph>
            <Paragraph>
              You should be aware that if we are requested by the police or any
              other regulatory or government authority investigating suspected
              illegal activities to provide your personal information or any
              other information we obtain about you, we are entitled to do so.
              You agree that you do not object to us using your personal
              information for any of the purposes outlined in this privacy
              policy and you confirm that you do not and will not consider any
              of these purposes as a breach of any of your rights under the
              Privacy and Electronic Communications (EC Directive) Regulations
              2003.
            </Paragraph>
            <Paragraph>
              In circumstances where you contact us by telephone, calls may be
              recorded for quality, training and security purposes.
            </Paragraph>
            <Paragraph>
              We will not keep your personal information processed by us for any
              purpose or purposes for longer than is necessary for that purpose
              or for those purposes.
            </Paragraph>
            <Paragraph>
              Under data protection legislation you have the right to access
              information held about you.
            </Paragraph>
            <Title2>How can I unsubscribe?</Title2>
            <Paragraph>
              If at any point you no longer wish us to hold your personal
              information or you do not wish to receive information from us then
              there are a number of ways you can unsubscribe. These are as
              follows:
            </Paragraph>
            <Paragraph>
              <Paragraph2>
                • You can click onto the "unsubscribe" link in any communication
                that we send to you by email which will automatically
                unsubscribe you from that type of communication. Please also
                note that you may continue to receive communications which are
                already on route to you for a short period of time while your
                relevant unsubscribe request is being processed.
              </Paragraph2>
              <Paragraph2>
                • Alternatively, you can contact us by email to
                <MailTo href="mailto:info@michaelallenpartners.com">
                  info@michaelallenpartners.com
                </MailTo>
              </Paragraph2>
            </Paragraph>
            <Paragraph>
              The Website may, from time to time, contain links to and from the
              websites of our partner networks, advertisers and affiliates. If
              you follow a link to any of these websites, please note that these
              websites have their own privacy policies and we do not accept any
              responsibility or liability for these policies. Please check these
              policies before you submit any personal details to these websites.
            </Paragraph>
            <Paragraph>
              For any questions regarding this policy, please contact us at
              <MailTo href="mailto:info@michaelallenpartners.com">
                info@michaelallenpartners.com
              </MailTo>
            </Paragraph>
            <Paragraph>Updated: 1st May 2020</Paragraph>
          </Paragraph1>
        </ContactContent>
      </ContactWrapper>
    </Layout>
  )
}

export default Contact
